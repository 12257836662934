import {nl} from '@formkit/i18n';
import {createConfig} from '@formkit/core';
import {generateClasses} from '@formkit/themes';
import {autocomplete, createProPlugin, datepicker, repeater, taglist, transferlist} from '@formkit/pro-unlimited';
import {createInput} from '@formkit/vue';
import {FormKitArticlePickerInput, FormKitImageInput, FormKitRadioBox, FormKitRadioList, FormKitSourceInput} from '#components';
import {genesisIcons} from '@formkit/icons';

const pro = createProPlugin('fk-58272dd8d66', {
  autocomplete,
  datepicker,
  repeater,
  taglist,
  transferlist,
});

export default createConfig({
  plugins: [pro],
  locales: {nl},
  locale: 'nl',
  icons: genesisIcons,

  inputs: {
    articles: createInput(FormKitArticlePickerInput, {
      props: [
        'alternative',
        'excluded',
        'max',
        'metadata',
        'supplier',
      ],
    }),
    image: createInput(FormKitImageInput, {
      props: [
        'planUuid',
      ],
    }),
    radioBox: createInput(FormKitRadioBox, {
      props: [
        'options',
        'onValue',
        'offValue',
        'optionsLoader',
      ],
      slots: ['default'],
    }),
    radioList: createInput(FormKitRadioList, {
      props: [
        'options',
        'onValue',
        'offValue',
        'optionsLoader',
      ],
    }),
    source: createInput(FormKitSourceInput, {
      props: [
        'sources',
      ],
    }),
  },

  config: {
    classes: generateClasses({
      global: {
        fieldset: 'border border-gray-200 rounded px-2 pb-1',
        help: 'text-sm text-gray-500',
        optionHelp: 'text-sm text-gray-500',
        inner: 'formkit-disabled:bg-gray-200 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none',
        input: 'appearance-none bg-transparent focus:outline-none focus:ring-0 focus:shadow-none',
        label: 'block mb-1 font-bold text-sm',
        legend: 'font-bold text-lg',
        loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
        message: 'text-red-500 mb-1 text-xs',
        messages: 'list-none p-0 mt-1 mb-0',
        outer: 'mb-4 formkit-disabled:opacity-50',
        prefixIcon: 'w-10 flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl border-r border-gray-400 bg-white bg-gradient-to-b from-transparent to-gray-200 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
        suffixIcon: 'box-content w-7 pr-3 p-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
      },

      // Family styles apply to all inputs that share a common family
      'family:box': {
        decorator: 'block relative size-4 mr-2 rounded bg-white border-2 border-white ring-1 ring-gray-400 peer-checked:ring-green peer-checked:bg-green text-transparent',
        decoratorIcon: 'flex p-[3px] w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
        help: 'mb-2 mt-1.5',
        input: 'absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
        inner: '$remove:formkit-disabled:bg-gray-200',
        label: '$reset text-sm text-gray-700 mt-1 select-none',
        wrapper: 'flex items-center mb-1',
      },
      'family:button': {
        input: '$reset inline-flex items-center bg-info-light text-white hover:bg-[#4EA0BC] font-semibold px-4 h-10 py-1 rounded-md focus-visible:outline-2 focus-visible:outline-info-light focus-visible:outline-offset-2 formkit-disabled:bg-gray-400 formkit-loading:before:w-4 formkit-loading:before:h-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-3xl formkit-loading:before:border-white formkit-loading:before:animate-spin',
        wrapper: 'mb-1',
        prefixIcon: '$reset block w-4 mr-2 stretch',
        suffixIcon: '$reset block w-4 ml-2 stretch',
      },
      'family:dropdown': {
        dropdownWrapper: 'my-2 w-full shadow-lg rounded [&::-webkit-scrollbar]:hidden',
        emptyMessageInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-gray-500 [&>span]:mr-3 [&>span]:ml-0',
        inner: 'relative flex ring-1 ring-gray-400 focus-within:ring-info-light focus-within:ring-2 rounded mb-2 formkit-disabled:focus-within:ring-gray-400 formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-blue-500',
        input: 'w-full px-3 py-2',
        listbox: 'bg-white shadow-lg rounded overflow-hidden',
        listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
        listitem: 'pl-7 relative hover:bg-gray-300 data-[is-active="true"]:bg-gray-300 aria-selected:bg-blue-600 aria-selected:text-white data-[is-active="true"]:aria-selected:bg-blue-600 data-[is-active="true"]:aria-selected:bg-blue-700',
        loaderIcon: 'ml-auto',
        loadMoreInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-blue-500 formkit-loading:text-gray-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
        option: 'p-2.5',
        optionLoading: 'pl-2.5 text-gray-400',
        placeholder: 'p-2.5 text-gray-400',
        selector: 'flex w-full justify-between items-center min-h-[2.625em] [&u] cursor-default',
        selection: 'flex w-full',
        selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
        selectIcon: 'flex box-content w-4 px-2 self-stretch grow-0 shrink-0 [&>svg]:w-[1em] cursor-pointer',
      },
      'family:text': {
        inner: 'flex items-center ring-1 ring-gray-400 focus-within:ring-info-light focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded',
        input: 'w-full px-3 py-2 border-none text-base text-gray-700 placeholder-gray-400',
      },
      'family:date': {
        inner: 'flex items-center ring-1 ring-gray-400 focus-within:ring-info-light focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded',
        input: 'w-full px-3 py-2 border-none text-gray-700 placeholder-gray-400',
      },

      // Specific styles apply only to a given input type
      checkbox: {
        fieldset: '$reset',
      },
      color: {
        inner:
          'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
        input:
          '$reset appearance-none w-full cursor-pointer border-none rounded p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none',
        suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto',
      },
      file: {
        fileItem: 'flex items-center text-gray-800 mb-1 last:mb-0',
        fileItemIcon: 'w-4 mr-2 shrink-0',
        fileList: 'shrink grow peer px-3 py-2 formkit-multiple:data-[has-multiple="true"]:mb-6',
        fileName: 'break-all grow text-ellipsis',
        fileRemove: 'relative z-[2] ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-blue-500 peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
        fileRemoveIcon: 'block text-base w-3 relative z-[2]',
        inner: 'relative cursor-pointer formkit-multiple:[&>button]:absolute',
        input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
        noFiles: 'flex w-full items-center px-3 py-2 text-gray-400',
        noFilesIcon: 'w-4 mr-2',
      },
      radio: {
        decorator: 'rounded-full',
        decoratorIcon: 'w-5 p-[5px]',
        fieldset: '$reset',
      },
      range: {
        inner: '$reset flex items-center',
        input: '$reset w-full mb-1 h-2 p-0 rounded-full',
        prefixIcon: '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
        suffixIcon: '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
      },
      select: {
        inner: 'flex relative items-center rounded mb-1 ring-1 ring-gray-400 focus-within:ring-info-light focus-within:ring-2 [&>span:first-child]:focus-within:text-blue-500',
        input: 'w-full pl-3 pr-8 py-2 border-none text-base text-gray-700 placeholder-gray-400 formkit-multiple:p-0 data-[placeholder="true"]:text-gray-400 formkit-multiple:data-[placeholder="true"]:text-inherit',
        selectIcon: 'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none [&>svg]:w-[1em]',
        option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-700',
      },
      textarea: {
        inner: 'flex rounded mb-1 ring-1 ring-gray-400 focus-within:ring-2 focus-within:ring-info-light [&>label:first-child]:focus-within:text-blue-500',
        input: 'block w-full h-32 px-3 py-3 border-none text-base text-gray-700 placeholder-gray-400 focus:shadow-outline',
      },

      // PRO input styles
      autocomplete: {
        closeIcon: 'block grow-0 shrink-0 w-3 mr-3.5',
        inner: 'relative',
        option: 'grow text-ellipsis',
        selectionWrapper: `
          absolute left-0 top-0 right-0 bottom-0 flex rounded bg-gray-100
          formkit-multiple:static formkit-multiple:mt-0 formkit-multiple:mb-2
        `,
      },

      datepicker: {
        inner: 'relative',
        panelWrapper:
          'absolute top-[calc(100%_+_0.5em)] shadow-[0_0_1.25em_rgba(0,0,0,.25)] rounded-md p-5 bg-white z-10',
        panelHeader:
          'grid grid-cols-[2.5em_1fr_2.5em] justify-center items-center border-b-2 mb-4 pb-4',
        input: 'selection:bg-blue-400',
        monthsHeader: 'flex items-center justify-center col-start-2 col-end-2',
        timeHeader: 'flex items-center justify-center col-start-2 col-end-2',
        overlayPlaceholder: 'text-gray-400',
        months: 'flex flex-wrap',
        month: `
          flex items-center justify-center
          w-[calc(33%_-_1em)] m-2 p-2 rounded-md
          bg-gray-200
          aria-selected:bg-blue-500 aria-selected:text-white
          focus:outline focus:outline-2 focus:outline-blue-500 focus:outline-offset-2 focus:bg-white focus:text-black
          data-[is-extra=true]:opacity-25
          formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
        `,
        yearsHeader: 'flex items-center justify-center col-start-2 col-end-2',
        years: 'flex flex-wrap max-w-[35em]',
        year: `
          flex items-center justify-center
          w-[calc(20%_-_1em)] m-2 p-2 rounded-md
          bg-gray-200
          aria-selected:bg-blue-500 aria-selected:text-white
          focus:outline focus:outline-2 focus:outline-blue-500 focus:outline-offset-2 focus:bg-white focus:text-black
          data-[is-extra=true]:opacity-25
          formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
        `,
        weekDays: 'flex',
        weekDay:
          'flex w-[2.25em] h-[1em] m-1 items-center justify-center rounded-md font-medium lowercase',
        week: 'flex formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none',
        dayCell: `
          flex items-center justify-center
          w-[2.25em] h-[2.25em] m-1 p-2 rounded-md
          bg-gray-200
          aria-selected:bg-blue-500 aria-selected:text-white
          focus:outline focus:outline-2 focus:outline-blue-500 focus:outline-offset-2 focus:bg-white focus:text-black
          data-[is-extra=true]:opacity-25
          formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
        `,
        timeInput: 'w-full border-2 border-gray-300 rounded-md p-2 my-[2em] focus-visible:outline-blue-500',
        daysHeader: 'flex items-center justify-center',
        prev: 'mr-auto px-3 py-1 hover:bg-gray-100 hover:rounded-lg col-start-1 col-end-1',
        prevLabel: 'hidden',
        prevIcon: 'flex w-3 select-none [&>svg]:w-full',
        dayButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-blue-500',
        monthButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-blue-500',
        yearButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-blue-500',
        next: 'ml-auto px-3 py-1 hover:bg-gray-100 hover:rounded col-start-3 col-end-3',
        nextLabel: 'hidden',
        nextIcon: 'flex w-3 select-none [&>svg]:w-full',
        openButton: `
          appearance-none border-0 bg-transparent flex p-0 self-stretch cursor-pointer
          focus-visible:outline-none focus-visible:text-white focus-visible:bg-blue-500
        `,
        calendarIcon: 'flex w-8 grow-0 shrink-0 self-stretch select-none [&>svg]:w-full [&>svg]:m-auto [&>svg]:max-h-[1em] [&>svg]:max-w-[1em]',
      },

      repeater: {
        content: 'grow p-3 flex flex-col align-center',
        controlLabel: 'absolute opacity-0 pointer-events-none',
        controls: 'flex flex-col items-center justify-center bg-gray-100 p-3',
        downControl: 'hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25',
        fieldset: 'py-4 px-5',
        help: 'mb-2 mt-1.5',
        item: 'flex w-full mb-1 rounded border border-gray-200',
        moveDownIcon: 'block w-3 my-1',
        moveUpIcon: 'block w-3 my-1',
        removeControl: 'hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25',
        removeIcon: 'block w-5 my-1',
        upControl: 'hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25',
      },

      taglist: {
        input: 'px-1 py-1 w-[0%] grow',
        removeSelection: 'w-2.5 mx-1 self-center text-black leading-none',
        tag: 'flex items-center my-1 p-1 bg-gray-200 text-xs rounded-full',
        tagWrapper: 'mr-1 focus:outline-none focus:text-white [&>div]:focus:bg-blue-500 [&>div>button]:focus:text-white',
        tagLabel: 'pl-2 pr-1',
        tags: 'flex items-center flex-wrap w-full py-1.5 px-2',
      },

      toggle: {
        altLabel: 'block w-full mb-1 font-bold text-sm',
        inner: '$reset inline-block mr-2',
        input: 'peer absolute opacity-0 pointer-events-none',
        innerLabel: 'text-[10px] font-bold absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1',
        thumb: 'relative left-0 aspect-square rounded-full transition-all w-5 bg-gray-100',
        track: 'p-0.5 min-w-[3em] relative rounded-full transition-all bg-gray-400 peer-checked:bg-blue-500 peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0',
        valueLabel: 'font-bold text-sm',
        wrapper: 'flex flex-wrap items-center mb-1',
      },

      transferlist: {
        outer: `
          [&_.dnd-placeholder]:bg-blue-500 [&_.dnd-placeholder]:text-white
          [&_.dnd-placeholder_svg]:text-white
          [&_.dnd-children-hidden]:w-full [&_.dnd-children-hidden]:p-0 [&_.dnd-children-hidden]:flex [&_.dnd-children-hidden]:flex-col [&_.dnd-children-hidden]:border-none
          [&_.dnd-children-hidden_span]:hidden
          [&_.dnd-children-hidden_.formkit-transferlist-option]:hidden
          [&_.dnd-multiple-selections_span]:inline-block
          [&_.dnd-multiple-selections_.formkit-transferlist-option]:inline-block
        `,
        fieldset: '$reset max-w-2xl',
        wrapper: 'flex max-h-[350px] flex-col sm:flex-row justify-between w-full max-w-none',
        help: 'pb-2',
        transferlist: 'sm:w-3/5 shadow-md flex flex-col min-h-[350px] max-h-[350px] border rounded overflow-hidden select-none bg-gray-50',
        transferlistHeader: 'flex bg-gray-100 justify-between items-center border-b p-3',
        transferlistHeaderItemCount: 'ml-auto text-sm',
        transferlistListItems: 'list-none bg-gray-50 h-full sm:max-w-xs overflow-x-hidden overflow-y-auto',
        transferlistListItem: 'pl-8 relative aria-selected:bg-blue-600 aria-selected:data-[is-active=true]:bg-blue-600 aria-selected:text-white aria-selected:data-[is-active=true]:text-white first:-mt-px first:border-t py-2 px-3 flex relative border-b bg-white data-[is-active=true]:text-blue-500 data-[is-active=true]:bg-gray-100 cursor-pointer group-data-[is-max=true]:cursor-not-allowed items-center',
        transferlistOption: 'text-sm',
        transferControls: 'flex sm:flex-col justify-center mx-auto my-2 sm:mx-2 sm:my-auto border rounded',
        transferlistButton: 'text-sm disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-50 first:rounded-l last:rounded-r sm:first:rounded-t sm:last:rounded-b appearance-none p-2 m-0 cursor-pointer h-10 border-none rounded-none bg-gray-50 hover:outline disabled:hover:outline-none hover:outline-1 hover:outline-black hover:text-blue-500 disabled:hover:text-current hover:z-10',
        sourceEmptyMessage: 'appearance-none border-none w-full p-0 m-0 text-center text-gray-500 italic',
        sourceListItems: 'group-data-[is-max=true]:opacity-50',
        targetEmptyMessage: 'appearance-none border-none w-full p-0 m-0 text-center text-gray-500 italic',
        emptyMessageInner: 'flex items-center justify-center p-2 text-sm',
        transferlistControls: 'bg-white px-3 py-2 border-b',
        transferlistSearch: 'flex border rounded items-center',
        transferlistSearchInput: 'border-none p-1 w-full bg-transparent outline-none text-sm',
        controlLabel: 'hidden',
        selectedIcon: 'w-3 absolute left-3 select-none',
        fastForwardIcon: 'w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0',
        moveRightIcon: 'w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0',
        moveLeftIcon: 'w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0',
        rewindIcon: 'w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0',
      },
    }),
  },
});
