export default defineNuxtRouteMiddleware(() => {
  const gtm = useGtm();
  const user = useSofieUserWhenAuthenticated();

  if (user.value) {
    gtm?.push({
      user_id: user.value.uuid,
      user_role: user.value.roles.map(role => ROLE_LABELS[role]).join(', ') || 'Leverancier',
      interal_user: String(user.value.is_internal_user),
    });
  }
});
