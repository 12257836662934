import moment from 'moment';

export default defineNuxtPlugin(() => {
  moment.locale('nl');
  // moment.tz.setDefault('Europe/Amsterdam');
  return {
    provide: {
      moment,
    },
  };
});
