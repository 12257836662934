import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faBarcode,
  faBoxOpen,
  faChartBar,
  faChartGantt,
  faChartLine,
  faChartPie,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboard,
  faClipboardCheck,
  faClipboardQuestion,
  faClock,
  faCog,
  faComment,
  faCow,
  faEuro,
  faExternalLink,
  faEye,
  faLaptop,
  faLink,
  faNewspaper,
  faPencil,
  faPercentage,
  faPhone,
  faPlus,
  faSort,
  faUsers,
  faWarning,
  faX,
} from '@fortawesome/free-solid-svg-icons';

export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    library.add(
      faChevronUp,
      faChevronDown,
      faUsers,
      faChevronRight,
      faChevronLeft,
      faX,
      faNewspaper,
      faClipboard,
      faPencil,
      faChartPie,
      faChartBar,
      faChartLine,
      faCog,
      faClipboardCheck,
      faClipboardQuestion,
      faChartGantt,
      faEuro,
      faBoxOpen,
      faClock,
      faPhone,
      faLaptop,
      faCow,
      faPercentage,
      faBarcode,
      faComment,
      faLink,
      faEye,
      faSort,
      faWarning,
      faExternalLink,
      faPlus,
    );

    nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon);
  },
});
