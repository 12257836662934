export default defineNuxtPlugin(() => {
  const user = useSofieUserWhenAuthenticated();

  return {
    provide: {
      auth: {
        hasScope(scope: string) {
          return user.value?.scope.includes(scope) ?? false;
        },
        user,
      },
    },
  };
});
