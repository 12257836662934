<template>
  <div class="flex gap-4">
    <FormKit
      :model-value="context._value"
      type="select"
      outer-class="$reset flex-grow"
      :validation="context.attrs.validation"
      :validation-name="context.attrs.validationName"
      :options="context.attrs.options"
      :ignore="true"
      @update:model-value="context.node.input($event)"
    />
    <UiButton
      label="Jaarkalender openen"
      variant="secondary"
      @click="calendarOpen = true"
    />

    <BaseModal
      v-model:open="calendarOpen"
      footer-button-text="Sluiten"
      :static-width="false"
      heading="Jaarplanning"
    >
      <div class="space-y-8">
        <div
          v-for="(categories, year) in categorizedSources"
          :key="year"
        >
          <h2 class="text-2xl">
            {{ year }} jaarplanning
          </h2>
          <div
            v-for="(sources, category) in categories"
            :key="category"
            class="mt-4"
          >
            <div class="grid grid-cols-6 gap-4">
              <h3 class="col-span-2">
                {{ category }}
              </h3>
              <div class="font-semibold">
                Looptijd
              </div>
              <div class="font-semibold">
                Inladingsperiode
              </div>
              <div class="font-semibold">
                Deadline inschrijving advertenties
              </div>
              <div class="font-semibold">
                Aanleveren advertenties
              </div>
            </div>
            <a
              v-for="source in sources"
              :key="source.id"
              type="button"
              href="javascript:;"
              class="mt-1 grid grid-cols-6 gap-4 hover:text-primary"
              @click="setSource(source)"
            >
              <div
                class="col-span-2 text-left"
              >
                {{ source.filteredTitle }}
              </div>
              <div v-if="source.duration_start && source.duration_end">
                {{ source.duration_start }} t/m {{ source.duration_end }}
              </div>
              <div v-else-if="source.duration_start">
                vanaf {{ source.duration_start }}
              </div>
              <div v-else>
                tot {{ source.duration_end }}
              </div>
              <div>
                {{ source.loading_start || 'nvt' }}
              </div>
              <div>
                {{ source.deadline ? subtractFourWeeks(source.deadline) : 'nvt' }}
              </div>
              <div>
                {{ source.deadline || 'nvt' }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script setup lang="ts">
import type {FormKitFrameworkContext} from '@formkit/core';
import {DateTime} from 'luxon';

type Source = {
  id: number
  deadline: string
  duration_end: string
  duration_start: string
  filteredTitle: string
  loading_start: string
  title: string
  type: string
};

const props = defineProps<{
  context: FormKitFrameworkContext
}>();

const calendarOpen = ref(false);

const sourcesYears = computed<Record<number, Source[]>>(() => {
  if (!Array.isArray(props.context.sources)) {
    return [];
  }

  const years: Record<number, Source[]> = {};

  props.context.sources.forEach((source) => {
    const year = new Date(source.duration_start).getFullYear();

    years[year] ??= [];
    years[year].push(source);
  });

  return years;
});

const categorizedSources = computed(() => {
  const categories: Record<number, Record<string, Source[]>> = {};

  Object.values(sourcesYears.value).forEach((sources) => {
    const year = new Date(sources[0].duration_start).getFullYear();

    categories[year] = {};

    sources.forEach((source) => {
      const category = source.type + ' ' + source.title.replace(/[0-9#]/g, '');

      categories[year][category] ??= [];
      categories[year][category].push({
        ...source,
        filteredTitle: source.title.split(' - ').join(' '),
      });
    });
  });

  return categories;
});

function subtractFourWeeks(date: string) {
  return DateTime.fromISO(date)
    .minus({weeks: 4})
    .toSQLDate();
}

function setSource(source: Source) {
  props.context.node.input((source.id).toString());

  calendarOpen.value = false;
}
</script>
