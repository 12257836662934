import type {RouteLocationNormalizedGeneric} from 'vue-router';

export default defineNuxtRouteMiddleware((route) => {
  const user = useSofieUserWhenAuthenticated();

  // All pages using scopes should be authenticated, so if the user is not authenticated, return and let the auth middleware handle it
  if (!user.value) {
    return;
  }

  const scope = findRouteOption(route, 'scope');

  if (typeof scope === 'string' && !hasUserScope(scope)) {
    throw createError({
      statusCode: 403,
    });
  }
});

// Better version of the routeOption method in @nuxtjs/auth that also includes checking for array content
function findRouteOption(route: RouteLocationNormalizedGeneric, key: string) {
  if (key in route.meta) {
    return route.meta[key];
  }

  // Loop over all the matched pages (will most likely always be only one match)
  for (const m of route.matched) {
    // Loop over all the components in the page
    for (const component of Object.values(m.components ?? {})) {
      if (key in component) {
        return component[key] as string;
      }
    }
  }
}
