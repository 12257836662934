<template>
  <div>
    <CampaignsWizardArticlePicker
      :model-value="context._value"
      v-bind="{
        ...context.attrs,
        alternative: context.alternative || false,
        max: context.max || 1,
        supplier: context.supplier,
        metadata: context.metadata,
      }"
      @update-metadata="emit('update-metadata', $event)"
      @update:model-value="context.node.input($event)"
    />
  </div>
</template>

<script setup lang="ts">
import type {FormKitFrameworkContext} from '@formkit/core';

defineProps<{
  context: FormKitFrameworkContext
}>();

const emit = defineEmits<{
  'update-metadata': [
    metadata: Record<string, {
      print: boolean,
      discount_value: number | string
      discount_percentage: number | string
    }>
  ]
}>();
</script>
