import {UserRole} from '~/graphql/graphql';

export function hasUserScope(scope: string) {
  const user = useSofieUserWhenAuthenticated();

  return user?.value?.scope.includes(scope) ?? false;
}

export function useUserScope(scope: string) {
  return computed(() => hasUserScope(scope));
}

export function useUserRole() {
  const user = useSofieUserWhenAuthenticated();

  function hasRoles(roles: UserRole[]) {
    return !!user.value && user.value.roles.some(role => roles.includes(role));
  }

  function hasRolesComputed(roles: UserRole[]) {
    return computed(() => hasRoles(roles));
  }

  return {
    isSupplier: computed(() => (!!user.value && user.value.roles.length === 0) || hasRoles([UserRole.Supplier])),
    isBuyer: hasRolesComputed([UserRole.Buyer, UserRole.BuyerAdmin]),
    isBuyerAdmin: hasRolesComputed([UserRole.BuyerAdmin]),
    isEcommerce: hasRolesComputed([UserRole.Ecommerce]),
    isTraffic: hasRolesComputed([UserRole.Marketeer]),
    isConnect: computed(() => !!user.value?.is_connect || hasRoles([UserRole.ConnectManager, UserRole.ConnectAdmin])),
  };
}
