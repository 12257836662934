import revive_payload_client_QEC2k1zvVs from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xlyiyolpfvw6ei2ixxh4rzxvui/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_8YeRxmbUK5 from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xlyiyolpfvw6ei2ixxh4rzxvui/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_35nzn8vcS3 from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xlyiyolpfvw6ei2ixxh4rzxvui/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_JxbI7p3PdU from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@22.8.6_terser@5._sipqy53jdsykwomdip7svvi52u/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_xsCS1Lzhxt from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xlyiyolpfvw6ei2ixxh4rzxvui/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_K4YjXEVvsI from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xlyiyolpfvw6ei2ixxh4rzxvui/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_x9HAUdBZBz from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xlyiyolpfvw6ei2ixxh4rzxvui/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_y3OXaS7TCX from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xlyiyolpfvw6ei2ixxh4rzxvui/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ECPXdJmsWj from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.3_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_Z4EcXR0mUe from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_xlyiyolpfvw6ei2ixxh4rzxvui/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_EfOcUJziTg from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.24.3_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_jeVTgaKx1G from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.24.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_2hO7GTq29L from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.24.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formkitPlugin_pZqjah0RUG from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_KcJ25vlQoj from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.2_graphql-ws@5.16.0_graphql@16.9.0__react-dom@18.3.1_react@_vmehs5k4ccojzkc7zunuzmak6i/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_pEL1i2TAZf from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_magicast@0.3.5_next-auth@4.21.1_next@13.5.6_@babel+core@7.26.0_reac_bmptzwffxtli2lztvuz4uq75uq/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import plugin_0mGvPPPiVd from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node_otojepmhd4ebmiyj2x3ttyxyn4/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import errors_D68ZPEV5JP from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_magicast@0.3.5_nuxt@3.13.2_@parcel+watc_6vflsbpqsmwc3hmcnxib62jkni/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_3FzvaAhi8n from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_magicast@0.3.5_nuxt@3.13.2_@parcel+watc_6vflsbpqsmwc3hmcnxib62jkni/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_afJAA5zWBz from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.2_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__magi_h3bt7siahobdm2uqclid5prpvm/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_KJiR82bArJ from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.2_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__magi_h3bt7siahobdm2uqclid5prpvm/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_CLxtxp1E6J from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.3_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__x2za6xxb7owaoq5son4i3rrx2e/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_myNa33xyoD from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.3_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__x2za6xxb7owaoq5son4i3rrx2e/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import auth_vT9JWWT9pN from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/plugins/auth.ts";
import directives_8CcCirWtnE from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/plugins/directives.ts";
import fontawesome_cn2c4tOOHz from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/plugins/fontawesome.ts";
import moment_NWWiRYbNqj from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/plugins/moment.ts";
import toast_ysMjUcU7eJ from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/plugins/toast.ts";
import tooltip_Di9mlKVbnI from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/plugins/tooltip.ts";
import v_calendar_dxaVzst4iS from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/plugins/v-calendar.ts";
import vimeo_player_ZFOjUs2IlB from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/plugins/vimeo-player.ts";
export default [
  revive_payload_client_QEC2k1zvVs,
  unhead_8YeRxmbUK5,
  router_35nzn8vcS3,
  _0_siteConfig_JxbI7p3PdU,
  payload_client_xsCS1Lzhxt,
  navigation_repaint_client_K4YjXEVvsI,
  check_outdated_build_client_x9HAUdBZBz,
  chunk_reload_client_y3OXaS7TCX,
  plugin_vue3_ECPXdJmsWj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Z4EcXR0mUe,
  plugin_EfOcUJziTg,
  switch_locale_path_ssr_jeVTgaKx1G,
  i18n_2hO7GTq29L,
  formkitPlugin_pZqjah0RUG,
  plugin_KcJ25vlQoj,
  plugin_pEL1i2TAZf,
  plugin_0mGvPPPiVd,
  errors_D68ZPEV5JP,
  i18n_3FzvaAhi8n,
  apollo_afJAA5zWBz,
  sofie_required_version_KJiR82bArJ,
  apollo_CLxtxp1E6J,
  auth_myNa33xyoD,
  auth_vT9JWWT9pN,
  directives_8CcCirWtnE,
  fontawesome_cn2c4tOOHz,
  moment_NWWiRYbNqj,
  toast_ysMjUcU7eJ,
  tooltip_Di9mlKVbnI,
  v_calendar_dxaVzst4iS,
  vimeo_player_ZFOjUs2IlB
]