<template>
  <transition
    enter-active-class="transition ease-out duration-300 transform"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-200 transform"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-show="open"
      class="fixed inset-0 z-50 cursor-default overflow-y-auto"
    >
      <div class="flex min-h-screen items-center justify-center text-center sm:p-0">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray opacity-75" />
        </div>

        <div
          class="relative m-2 transform bg-white text-left align-bottom shadow-xl transition-all sm:m-8 md:rounded-lg"
          :class="staticWidth ? 'sm:max-w-screen-sm md:max-w-screen-lg w-full' : ''"
          role="dialog"
          aria-visible="true"
          aria-labelledby="visible-headline"
        >
          <div class="flex items-center justify-between border-b border-gray-light px-8 py-6">
            <div>
              <h3
                id="modal-headline"
                class="flex gap-2"
              >
                <div>
                  {{ heading }}
                </div>
                <nuxt-link
                  v-if="link"
                  :to="link"
                >
                  <font-awesome-icon icon="external-link" />
                </nuxt-link>
              </h3>
            </div>
            <div class="hidden sm:flex">
              <button
                type="button"
                class="text-info-light"
                @click="close"
              >
                <span class="sr-only">Sluiten</span>
                <!-- Heroicon name: outline/x -->
                <svg
                  class="size-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="px-8 py-6">
            <slot />
            <div class="mt-4 flex gap-2">
              <slot name="footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="close"
                  v-text="footerButtonText"
                />
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import type {RouteLocationRaw} from 'vue-router';

withDefaults(defineProps<{
  footerButtonText?: string
  heading: string
  link?: RouteLocationRaw
  staticWidth?: boolean
}>(), {
  footerButtonText: 'Bevestigen',
  staticWidth: true,
  link: undefined,
});

const open = defineModel<boolean>('open', {
  required: true,
});

function close() {
  open.value = false;
}

defineExpose({
  close,
});
</script>
