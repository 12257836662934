export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.vueApp.directive('click-outside', {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    });

    nuxtApp.vueApp.directive('href', (el, bindings) => {
      if (el.querySelector('a[href]') === null) {
        // Directive v-href set on element that does not contain an A element.
        return;
      }

      if (bindings.value === false) {
        // Explicitly ommitted
        return;
      }

      el.classList.add('cursor-pointer');

      el.addEventListener('click', (clicked) => {
        // Usage: v-href on parent element (previously data-href)
        if (clicked.tagName === 'A' && clicked.getAttribute('href')) {
          // Just follow a when clicked on it
          return;
        }

        const a = el.querySelector('a');

        if (a) {
          const target = a.getAttribute('target');

          if (typeof target !== typeof undefined && target) {
            window.open(a.getAttribute('href'), target);
          } else {
            navigateTo({
              path: a.getAttribute('href'),
            });
          }
        }
      });
    });
  },
});
