<template>
  <div>
    <strong v-if="label">{{ label }}</strong>
    <ul v-if="errors.length">
      <li
        v-for="(error, i) in errors"
        :key="i"
        class="mb-1 text-xs text-error"
      >
        {{ error }}
      </li>
    </ul>
    <ul v-if="hasMissingPackshots">
      <li class="mb-1 text-xs text-gray-dark">
        Artikelen zonder packshot kunnen niet worden ingezet.
      </li>
    </ul>
    <div class="rounded-md border border-gray-400 bg-white p-2">
      <div class="flex items-center justify-between gap-2">
        <ul
          v-if="products.length"
          class="flex-grow"
        >
          <li
            v-for="p in productList"
            :key="p"
            class="border-b border-gray-light p-2"
          >
            <div class="flex justify-between">
              {{ productDetail(p) }}
              <button
                type="button"
                class="ml-1 text-primary outline-none"
                @click="remove(p)"
              >
                <span class="sr-only">Verwijderen</span>
                <svg
                  class="size-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 21 20"
                ><path
                  fill="#4A4A4A"
                  stroke="#5EC4E6"
                  d="m9.8 10.35.35-.35-.36-.35-3.7-3.72a.25.25 0 0 1 .34-.35l3.72 3.71.35.36.35-.36 3.72-3.7a.25.25 0 0 1 .35.34L11.2 9.65l-.36.35.36.35 3.72 3.72v.01a.25.25 0 0 1 .09.18.25.25 0 0 1-.26.26.25.25 0 0 1-.17-.08h-.01v-.01l-3.73-3.72-.35-.36-.35.36-3.72 3.72h-.01a.25.25 0 0 1-.37.02.25.25 0 0 1 .01-.36v-.01h.01l3.72-3.73Z"
                /></svg>
              </button>
            </div>
          </li>
        </ul>
        <div v-else-if="max === 1">
          Er is geen artikel geselecteerd
        </div>
        <div v-else>
          Er zijn geen artikelen geselecteerd
        </div>
      </div>
      <UiButton
        :label="max === 1 ? 'Selecteer artikel' : 'Selecteer artikelen'"
        class="mt-4"
        icon="plus"
        variant="secondary"
        @click="open = !open"
      />
    </div>
    <BaseModal
      v-model:open="open"
      :heading="max === 1 ? 'Artikel toevoegen' : 'Artikelen toevoegen'"
    >
      <div
        :class="[max > 1 ? 'grid-cols-2 gap-6' : '']"
        class="grid"
      >
        <div class="relative max-h-96 overflow-hidden rounded-xl border border-gray">
          <div class="bg-gray-lighter p-6 pb-2">
            <FormKit
              v-model="searchProducts"
              type="text"
              class=""
              placeholder="Zoeken in assortiment"
              :input-class="['border-none', 'bg-white focus:shadow-none']"
              ignore
            />
          </div>
          <div class="h-full overflow-scroll p-4">
            <FormKit
              v-model="products"
              :options="options"
              :type="max === 1 ? 'radio' : 'checkbox'"
              validation-name="artikel"
              ignore
            />
          </div>
        </div>
        <div
          v-if="alternative"
          class="max-h-96 overflow-y-auto"
        >
          <table class="table-striped-slim table text-sm">
            <thead>
              <tr class="font-semibold">
                <td>Artikelnummer</td>
                <td>Afgedrukt</td>
                <td>Korting %</td>
                <td>
                  <div class="flex items-center gap-2">
                    Korting €
                    <span
                      v-tooltip="{
                        content: 'Vul de korting per Handelseenheid of per KG in',
                        placement: 'top',
                        offset: 3,
                      }"
                    >
                      <svg
                        class="size-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="product in productList.filter((prod) => newMetadata[prod])"
                :key="product"
              >
                <td>{{ product }}</td>
                <td>
                  <input
                    v-model="newMetadata[product].print"
                    name="print"
                    type="checkbox"
                    @change="sendMetadataToForm"
                  >
                </td>
                <td>
                  <FormKit
                    v-model="newMetadata[product].discount_percentage"
                    name="discount_percentage"
                    type="number"
                    input-class="mb-0 p-2 h-6 w-16 rounded border border-gray outline-none"
                    min="0"
                    max="100"
                    step="0.1"
                    ignore
                    @change="sendMetadataToForm"
                  />
                </td>
                <td>
                  <FormKit
                    v-model="newMetadata[product].discount_value"
                    name="discount_value"
                    type="number"
                    input-class="mb-0 p-2 h-6 w-16 rounded border border-gray outline-none"
                    min="0"
                    step="0.01"
                    ignore
                    @change="sendMetadataToForm"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-4 grid grid-cols-5 gap-4 px-2">
            <button
              type="button"
              class="btn btn-secondary col-span-2 text-sm"
              @click="updateGlobalDiscounts"
            >
              Korting instellen
            </button>
            <div>
              <FormKit
                v-model="globalDiscountPercentage"
                name="global_discount_percentage"
                type="number"
                input-class="col-span-1 rounded border border-gray outline-none"
                min="0"
                max="100"
                step="0.1"
                ignore
              />
            </div>
            <div class="col-span-2">
              <FormKit
                v-model="globalDiscountValue"
                name="global_discount_value"
                type="number"
                input-class="rounded border border-gray outline-none"
                min="0"
                step="0.01"
                ignore
              />
            </div>
          </div>
        </div>
        <div
          v-else-if="max > 1"
          class="rounded border border-gray p-4"
        >
          <div
            v-for="product in productList"
            :key="product"
            class="mb-2 mr-2 inline-flex rounded bg-primary bg-opacity-25 px-2 py-1 text-sm"
          >
            {{ product }}
            <button
              type="button"
              class="ml-1 text-primary outline-none"
              @click="remove(product)"
            >
              <span class="sr-only">Verwijderen</span>
              <svg
                class="size-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 21 20"
              ><path
                fill="#4A4A4A"
                stroke="#5EC4E6"
                d="m9.8 10.35.35-.35-.36-.35-3.7-3.72a.25.25 0 0 1 .34-.35l3.72 3.71.35.36.35-.36 3.72-3.7a.25.25 0 0 1 .35.34L11.2 9.65l-.36.35.36.35 3.72 3.72v.01a.25.25 0 0 1 .09.18.25.25 0 0 1-.26.26.25.25 0 0 1-.17-.08h-.01v-.01l-3.73-3.72-.35-.36-.35.36-3.72 3.72h-.01a.25.25 0 0 1-.37.02.25.25 0 0 1 .01-.36v-.01h.01l3.72-3.73Z"
              /></svg>
            </button>
          </div>
        </div>
      </div>
      <template #footer>
        <ul v-if="validationErrors.length">
          <li
            v-for="(error, i) in validationErrors"
            :key="i"
            class="mb-1 text-right text-xs text-error"
          >
            {{ error }}
          </li>
        </ul>
        <div class="mt-4 flex flex-grow items-end">
          <div class="flex-grow space-x-4">
            <FormKit
              v-model="notYetAvailable"
              type="checkbox"
              outer-class="$reset"
              label="Mijn artikelen staan nog niet in de catalogus"
              ignore
            />
            <div
              v-if="notYetAvailable"
              class="w-1/3"
            >
              <FormKit
                v-model="notYetAvailableList"
                type="text"
                label="Artikelnummers invoeren"
                placeholder="1234567, 456789"
                :label-class="['!font-medium text-sm']"
                :input-class="['py-1 text-sm']"
                class="mt-2"
                help="Vul tussen de artikelnummers een komma in."
                outer-class=""
                ignore
              />
            </div>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-primary"
              @click="close"
            >
              Bevestigen
            </button>
          </div>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Array, String],
      required: true,
      default: '',
    },
    label: {
      type: String,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    supplier: {
      type: String,
      default: '',
    },
    excluded: {
      type: Array,
      default: () => ([]),
    },
    alternative: {
      type: Boolean,
      default: false,
    },
    metadata: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'update-metadata',
    'update:model-value',
  ],
  setup(props) {
    const user = useSofieUser();
    const {result} = useCatalogQuery({
      supplier: computed(() => props.supplier || user.value.supplier?.uuid),
    });

    return {
      catalog: computed(() => result.value?.catalog?.exsellItems ?? []),
    };
  },
  data: () => ({
    open: false,
    products: [],
    searchProducts: '',
    notYetAvailable: false,
    notYetAvailableList: '',
    newMetadata: {},
    validationErrors: [],
    globalDiscountPercentage: 0,
    globalDiscountValue: 0,
  }),
  computed: {
    options() {
      return (this.catalog || []).filter((product) => {
        if (!this.searchProducts) {
          return true;
        }

        const brand = product.brand.toLowerCase().includes(this.searchProducts.toLowerCase());
        const sku = product.sku.includes(this.searchProducts);
        const name = product.name.toLowerCase().includes(this.searchProducts.toLowerCase());

        return brand || sku || name;
      }).map(product => ({
        value: product.sku,
        label: `${product.sku} - ${product.name} - ${product.brand}`,
        disabled: this.excluded.includes(product.sku),
      }));
    },
    productList() {
      return Array.isArray(this.products) ? this.products : [this.products];
    },
    hasMissingPackshots() {
      return !!(this.catalog || []).find(product => !product.packshot);
    },
  },
  watch: {
    modelValue() {
      this.products = this.modelValue;
    },
    products() {
      this.$emit('update:model-value', this.products);
      this.updateMetadata();
    },
    notYetAvailableList() {
      const items = this.notYetAvailableList.split(',').filter((product) => {
        return product.replace(/\s/g, '').length !== 0;
      });
      this.$emit('update:model-value', this.max === 1 ? items[0] : items);
    },
  },
  mounted() {
    if (this.modelValue) {
      this.products = this.modelValue;
    }

    if (this.metadata) {
      this.newMetadata = {};
      this.newMetadata = JSON.parse(JSON.stringify(this.metadata));
    }
  },
  methods: {
    updateGlobalDiscounts() {
      Object.entries(this.newMetadata).forEach((val) => {
        const meta = JSON.parse(JSON.stringify(this.newMetadata));
        meta[val[0]].discount_percentage = this.globalDiscountPercentage;
        meta[val[0]].discount_value = this.globalDiscountValue;

        this.newMetadata = meta;
      });

      this.updateMetadata();
    },
    updateMetadata() {
      if (this.max <= 1) {
        return;
      }

      const meta = JSON.parse(JSON.stringify(this.newMetadata));
      Object.entries(meta).forEach((val) => {
        if (!this.products.includes(val[0])) {
          delete meta[val[0]];
        }
      });

      this.products.forEach((product) => {
        if (!meta[product]) {
          meta[product] = {
            print: false,
            discount_percentage: 0,
            discount_value: 0,
          };
        }
      });

      this.newMetadata = meta;

      this.sendMetadataToForm();
    },
    sendMetadataToForm() {
      this.$emit('update-metadata', this.newMetadata);
    },
    remove(id) {
      this.products = this.products.filter(product => product !== id);
      delete this.newMetadata[id];
    },
    productDetail(id) {
      const product = (this.catalog || []).find(product => product.sku === id);

      return product ? `${product.sku} - ${product.name} - ${product.brand}` : id;
    },
    close() {
      this.validationErrors = [];
      if (!this.alternative) {
        this.open = false;
        return;
      }

      if (!Object.entries(this.newMetadata).filter(i => i[1].print).length) {
        this.validationErrors.push('Er moet minimaal 1 product worden afgedrukt.');
      } else {
        this.open = false;
      }
    },
  },
};
</script>
