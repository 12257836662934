import { default as _91_46_46_46slug_93R89cspvfBBMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/[...slug].vue?macro=true";
import { default as _91token_9382bF6n4ymMMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/auth/invite/[token].vue?macro=true";
import { default as loginWfl8jjQSagMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/auth/login.vue?macro=true";
import { default as _91token_93inCk3WZEy3Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/auth/password/reset/[token].vue?macro=true";
import { default as index0wfwoyCqqUMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/auth/password/reset/index.vue?macro=true";
import { default as _91slug_93oCRINa3MQbMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/[slug].vue?macro=true";
import { default as editfGUn6rgnbnMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/bms-packages/[uuid]/edit.vue?macro=true";
import { default as index0i3BCXVPLZMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/bms-packages/[uuid]/index.vue?macro=true";
import { default as indexhoOH0mBlgZMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/bms-packages/index.vue?macro=true";
import { default as editQxCV1ThtgrMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/bms-positions/[uuid]/edit.vue?macro=true";
import { default as index2SBlU0ViOqMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/bms-positions/[uuid]/index.vue?macro=true";
import { default as indexjvM12Ww0h8Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/bms-positions/index.vue?macro=true";
import { default as indexxOGaGXfVwKMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/budgets/index.vue?macro=true";
import { default as indexU1Ih9UzFK9Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/campaigns/index.vue?macro=true";
import { default as index0qGKRsmuk2Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/index.vue?macro=true";
import { default as _91uuid_93cTMpf19DpLMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/series/[uuid].vue?macro=true";
import { default as indexVDBZUWg1mMMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/series/index.vue?macro=true";
import { default as editBaSfKZ4Dm9Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/[uuid]/budgets/[budgetUuid]/edit.vue?macro=true";
import { default as createiy01CsSWYuMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/[uuid]/budgets/create.vue?macro=true";
import { default as catalogwYyYW8HZOsMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/[uuid]/catalog.vue?macro=true";
import { default as indexKohz982gNWMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/[uuid]/index.vue?macro=true";
import { default as _91planUuid_93AeQLNjCAkjMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/[uuid]/plans/[planUuid].vue?macro=true";
import { default as createFlUHx5tswkMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/[uuid]/plans/create.vue?macro=true";
import { default as indexx6pkyTpf6kMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/index.vue?macro=true";
import { default as _91uuid_93h2Aaf2Hw9eMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/campaign-evaluation/[uuid].vue?macro=true";
import { default as indexGPYxTu2jMqMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/index.vue?macro=true";
import { default as _91slug_93Z2ZhFcIXrMMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/[slug].vue?macro=true";
import { default as audiencesisfPZD333xMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/audiences.vue?macro=true";
import { default as contactiogoDruTjsMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/contact.vue?macro=true";
import { default as indexRtssCSRE21Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/index.vue?macro=true";
import { default as _91slug_93AEmvJSQCh6Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/magazines/[slug].vue?macro=true";
import { default as indexFDtpvL95jeMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/magazines/index.vue?macro=true";
import { default as _91slug_938gvOhSXLcjMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/news/[slug].vue?macro=true";
import { default as indexO66qbrVoeDMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/news/index.vue?macro=true";
import { default as resourcesdMIwgp094yMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/resources.vue?macro=true";
import { default as _91slug_93Men8kvxvqFMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/insights/[slug].vue?macro=true";
import { default as general9OrSTzIDdUMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/insights/general.vue?macro=true";
import { default as indexFgCrYAg9BAMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/insights/index.vue?macro=true";
import { default as lostEGHgeV8pGOMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/insights/lost.vue?macro=true";
import { default as recurringlL48JPBl0dMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/insights/recurring.vue?macro=true";
import { default as whitespots2vuM0jkXkZMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/insights/whitespots.vue?macro=true";
import { default as introductieNRpQuWGhpiMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/introductie.vue?macro=true";
import { default as _91slug_93xc6DIJrWfRMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/[slug].vue?macro=true";
import { default as catalogmwVcg8perAMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/catalog.vue?macro=true";
import { default as fix_45the_45basicsgr44UZMN8JMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/fix-the-basics.vue?macro=true";
import { default as indexbLDN8WML9JMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/index.vue?macro=true";
import { default as _91uuid_93MvzMtV7V5rMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/series/[uuid].vue?macro=true";
import { default as createE3UOkEnjljMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/series/create.vue?macro=true";
import { default as index5C6ZWVv0NcMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/series/index.vue?macro=true";
import { default as editZ4mTQOeV69Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/shortcoming/[uuid]/edit.vue?macro=true";
import { default as indexkz7JVK8alOMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/shortcoming/[uuid]/index.vue?macro=true";
import { default as createOypKgEAbEUMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/shortcoming/create.vue?macro=true";
import { default as indexTVjeKUFwPxMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/shortcoming/index.vue?macro=true";
import { default as presentatie5mlSnBtzGDMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/presentatie.vue?macro=true";
import { default as profileRRQHFgkTsqMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/profile.vue?macro=true";
import { default as _91slug_93myU467pBhdMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/[slug].vue?macro=true";
import { default as editYTmM4qZQM3Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/[uuid]/edit.vue?macro=true";
import { default as evaluationX6Q46bOLaaMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/[uuid]/evaluation.vue?macro=true";
import { default as finishgg6shuH5oOMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/[uuid]/finish.vue?macro=true";
import { default as index8IG3CQ0yvqMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/[uuid]/index.vue?macro=true";
import { default as createLmMckkTm2fMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/create.vue?macro=true";
import { default as indexx1xFQZHlTiMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/index.vue?macro=true";
import { default as infoUlYjb9mWrGMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/info.vue?macro=true";
import { default as editGMTQ8weqx5Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-positions/[uuid]/edit.vue?macro=true";
import { default as indexwezjk5nNnUMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-positions/[uuid]/index.vue?macro=true";
import { default as createG6CK8s8VdSMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-positions/create.vue?macro=true";
import { default as indexoFhhFlX3DIMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-positions/index.vue?macro=true";
import { default as budgetIBRe4sfm0iMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/budget.vue?macro=true";
import { default as _91uuid_93vmHw89SQVYMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/budgets/[uuid].vue?macro=true";
import { default as indexB9TBtavEX3Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/campaigns/index.vue?macro=true";
import { default as _91slug_93GywnMXtqsLMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/cases/[slug].vue?macro=true";
import { default as indexubosjUdzMnMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/cases/index.vue?macro=true";
import { default as indexnopyEBvJJ2Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/index.vue?macro=true";
import { default as planningZvuYpp72LvMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/planning.vue?macro=true";
import { default as _91uuid_93xWbkXpMHY5Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/plans/[uuid].vue?macro=true";
import { default as _91id_93YWsbESvpjSMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/scenarios/[id].vue?macro=true";
import { default as roundtablesWyrWIY1RHrMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/roundtables.vue?macro=true";
import { default as index6JGKQYIt8SMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/xtra/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93R89cspvfBBMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/[...slug].vue")
  },
  {
    name: "auth-invite-token",
    path: "/auth/invite/:token()",
    meta: _91token_9382bF6n4ymMMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/auth/invite/[token].vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginWfl8jjQSagMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-password-reset-token",
    path: "/auth/password/reset/:token()",
    meta: _91token_93inCk3WZEy3Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/auth/password/reset/[token].vue")
  },
  {
    name: "auth-password-reset",
    path: "/auth/password/reset",
    meta: index0wfwoyCqqUMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/auth/password/reset/index.vue")
  },
  {
    name: "buying-slug",
    path: "/buying/:slug()",
    meta: _91slug_93oCRINa3MQbMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/[slug].vue")
  },
  {
    name: "buying-bms-packages-uuid-edit",
    path: "/buying/bms-packages/:uuid()/edit",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/bms-packages/[uuid]/edit.vue")
  },
  {
    name: "buying-bms-packages-uuid",
    path: "/buying/bms-packages/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/bms-packages/[uuid]/index.vue")
  },
  {
    name: "buying-bms-packages",
    path: "/buying/bms-packages",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/bms-packages/index.vue")
  },
  {
    name: "buying-bms-positions-uuid-edit",
    path: "/buying/bms-positions/:uuid()/edit",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/bms-positions/[uuid]/edit.vue")
  },
  {
    name: "buying-bms-positions-uuid",
    path: "/buying/bms-positions/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/bms-positions/[uuid]/index.vue")
  },
  {
    name: "buying-bms-positions",
    path: "/buying/bms-positions",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/bms-positions/index.vue")
  },
  {
    name: "buying-budgets",
    path: "/buying/budgets",
    meta: indexxOGaGXfVwKMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/budgets/index.vue")
  },
  {
    name: "buying-campaigns",
    path: "/buying/campaigns",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/campaigns/index.vue")
  },
  {
    name: "buying",
    path: "/buying",
    meta: index0qGKRsmuk2Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/index.vue")
  },
  {
    name: "buying-series-uuid",
    path: "/buying/series/:uuid()",
    meta: _91uuid_93cTMpf19DpLMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/series/[uuid].vue")
  },
  {
    name: "buying-series",
    path: "/buying/series",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/series/index.vue")
  },
  {
    name: "buying-suppliers-uuid-budgets-budgetUuid-edit",
    path: "/buying/suppliers/:uuid()/budgets/:budgetUuid()/edit",
    meta: editBaSfKZ4Dm9Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/[uuid]/budgets/[budgetUuid]/edit.vue")
  },
  {
    name: "buying-suppliers-uuid-budgets-create",
    path: "/buying/suppliers/:uuid()/budgets/create",
    meta: createiy01CsSWYuMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/[uuid]/budgets/create.vue")
  },
  {
    name: "buying-suppliers-uuid-catalog",
    path: "/buying/suppliers/:uuid()/catalog",
    meta: catalogwYyYW8HZOsMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/[uuid]/catalog.vue")
  },
  {
    name: "buying-suppliers-uuid",
    path: "/buying/suppliers/:uuid()",
    meta: indexKohz982gNWMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/[uuid]/index.vue")
  },
  {
    name: "buying-suppliers-uuid-plans-planUuid",
    path: "/buying/suppliers/:uuid()/plans/:planUuid()",
    meta: _91planUuid_93AeQLNjCAkjMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/[uuid]/plans/[planUuid].vue")
  },
  {
    name: "buying-suppliers-uuid-plans-create",
    path: "/buying/suppliers/:uuid()/plans/create",
    meta: createFlUHx5tswkMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/[uuid]/plans/create.vue")
  },
  {
    name: "buying-suppliers",
    path: "/buying/suppliers",
    meta: indexx6pkyTpf6kMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/buying/suppliers/index.vue")
  },
  {
    name: "campaign-evaluation-uuid",
    path: "/campaign-evaluation/:uuid()",
    meta: _91uuid_93h2Aaf2Hw9eMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/campaign-evaluation/[uuid].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexGPYxTu2jMqMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93Z2ZhFcIXrMMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/[slug].vue")
  },
  {
    name: "info-audiences",
    path: "/info/audiences",
    meta: audiencesisfPZD333xMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/audiences.vue")
  },
  {
    name: "info-contact",
    path: "/info/contact",
    meta: contactiogoDruTjsMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/contact.vue")
  },
  {
    name: "info",
    path: "/info",
    meta: indexRtssCSRE21Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/index.vue")
  },
  {
    name: "info-magazines-slug",
    path: "/info/magazines/:slug()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/magazines/[slug].vue")
  },
  {
    name: "info-magazines",
    path: "/info/magazines",
    meta: indexFDtpvL95jeMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/magazines/index.vue")
  },
  {
    name: "info-news-slug",
    path: "/info/news/:slug()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/news/[slug].vue")
  },
  {
    name: "info-news",
    path: "/info/news",
    meta: indexO66qbrVoeDMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/news/index.vue")
  },
  {
    name: "info-resources",
    path: "/info/resources",
    meta: resourcesdMIwgp094yMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/info/resources.vue")
  },
  {
    name: "insights-slug",
    path: "/insights/:slug()",
    meta: _91slug_93Men8kvxvqFMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/insights/[slug].vue")
  },
  {
    name: "insights-general",
    path: "/insights/general",
    meta: general9OrSTzIDdUMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/insights/general.vue")
  },
  {
    name: "insights",
    path: "/insights",
    meta: indexFgCrYAg9BAMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/insights/index.vue")
  },
  {
    name: "insights-lost",
    path: "/insights/lost",
    meta: lostEGHgeV8pGOMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/insights/lost.vue")
  },
  {
    name: "insights-recurring",
    path: "/insights/recurring",
    meta: recurringlL48JPBl0dMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/insights/recurring.vue")
  },
  {
    name: "insights-whitespots",
    path: "/insights/whitespots",
    meta: whitespots2vuM0jkXkZMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/insights/whitespots.vue")
  },
  {
    name: "introductie",
    path: "/introductie",
    meta: introductieNRpQuWGhpiMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/introductie.vue")
  },
  {
    name: "perfect-online-store-slug",
    path: "/perfect-online-store/:slug()",
    meta: _91slug_93xc6DIJrWfRMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/[slug].vue")
  },
  {
    name: "perfect-online-store-catalog",
    path: "/perfect-online-store/catalog",
    meta: catalogmwVcg8perAMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/catalog.vue")
  },
  {
    name: "perfect-online-store-fix-the-basics",
    path: "/perfect-online-store/fix-the-basics",
    meta: fix_45the_45basicsgr44UZMN8JMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/fix-the-basics.vue")
  },
  {
    name: "perfect-online-store",
    path: "/perfect-online-store",
    meta: indexbLDN8WML9JMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/index.vue")
  },
  {
    name: "perfect-online-store-series-uuid",
    path: "/perfect-online-store/series/:uuid()",
    meta: _91uuid_93MvzMtV7V5rMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/series/[uuid].vue")
  },
  {
    name: "perfect-online-store-series-create",
    path: "/perfect-online-store/series/create",
    meta: createE3UOkEnjljMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/series/create.vue")
  },
  {
    name: "perfect-online-store-series",
    path: "/perfect-online-store/series",
    meta: index5C6ZWVv0NcMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/series/index.vue")
  },
  {
    name: "perfect-online-store-shortcoming-uuid-edit",
    path: "/perfect-online-store/shortcoming/:uuid()/edit",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/shortcoming/[uuid]/edit.vue")
  },
  {
    name: "perfect-online-store-shortcoming-uuid",
    path: "/perfect-online-store/shortcoming/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/shortcoming/[uuid]/index.vue")
  },
  {
    name: "perfect-online-store-shortcoming-create",
    path: "/perfect-online-store/shortcoming/create",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/shortcoming/create.vue")
  },
  {
    name: "perfect-online-store-shortcoming",
    path: "/perfect-online-store/shortcoming",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/perfect-online-store/shortcoming/index.vue")
  },
  {
    name: "presentatie",
    path: "/presentatie",
    meta: presentatie5mlSnBtzGDMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/presentatie.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileRRQHFgkTsqMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/profile.vue")
  },
  {
    name: "reach-campaigns-slug",
    path: "/reach-campaigns/:slug()",
    meta: _91slug_93myU467pBhdMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/[slug].vue")
  },
  {
    name: "reach-campaigns-bms-packages-uuid-edit",
    path: "/reach-campaigns/bms-packages/:uuid()/edit",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/[uuid]/edit.vue")
  },
  {
    name: "reach-campaigns-bms-packages-uuid-evaluation",
    path: "/reach-campaigns/bms-packages/:uuid()/evaluation",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/[uuid]/evaluation.vue")
  },
  {
    name: "reach-campaigns-bms-packages-uuid-finish",
    path: "/reach-campaigns/bms-packages/:uuid()/finish",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/[uuid]/finish.vue")
  },
  {
    name: "reach-campaigns-bms-packages-uuid",
    path: "/reach-campaigns/bms-packages/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/[uuid]/index.vue")
  },
  {
    name: "reach-campaigns-bms-packages-create",
    path: "/reach-campaigns/bms-packages/create",
    meta: createLmMckkTm2fMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/create.vue")
  },
  {
    name: "reach-campaigns-bms-packages",
    path: "/reach-campaigns/bms-packages",
    meta: indexx1xFQZHlTiMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/index.vue")
  },
  {
    name: "reach-campaigns-bms-packages-info",
    path: "/reach-campaigns/bms-packages/info",
    meta: infoUlYjb9mWrGMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-packages/info.vue")
  },
  {
    name: "reach-campaigns-bms-positions-uuid-edit",
    path: "/reach-campaigns/bms-positions/:uuid()/edit",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-positions/[uuid]/edit.vue")
  },
  {
    name: "reach-campaigns-bms-positions-uuid",
    path: "/reach-campaigns/bms-positions/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-positions/[uuid]/index.vue")
  },
  {
    name: "reach-campaigns-bms-positions-create",
    path: "/reach-campaigns/bms-positions/create",
    meta: createG6CK8s8VdSMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-positions/create.vue")
  },
  {
    name: "reach-campaigns-bms-positions",
    path: "/reach-campaigns/bms-positions",
    meta: indexoFhhFlX3DIMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/bms-positions/index.vue")
  },
  {
    name: "reach-campaigns-budget",
    path: "/reach-campaigns/budget",
    meta: budgetIBRe4sfm0iMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/budget.vue")
  },
  {
    name: "reach-campaigns-budgets-uuid",
    path: "/reach-campaigns/budgets/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/budgets/[uuid].vue")
  },
  {
    name: "reach-campaigns-campaigns",
    path: "/reach-campaigns/campaigns",
    meta: indexB9TBtavEX3Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/campaigns/index.vue")
  },
  {
    name: "reach-campaigns-cases-slug",
    path: "/reach-campaigns/cases/:slug()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/cases/[slug].vue")
  },
  {
    name: "reach-campaigns-cases",
    path: "/reach-campaigns/cases",
    meta: indexubosjUdzMnMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/cases/index.vue")
  },
  {
    name: "reach-campaigns",
    path: "/reach-campaigns",
    meta: indexnopyEBvJJ2Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/index.vue")
  },
  {
    name: "reach-campaigns-planning",
    path: "/reach-campaigns/planning",
    meta: planningZvuYpp72LvMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/planning.vue")
  },
  {
    name: "reach-campaigns-plans-uuid",
    path: "/reach-campaigns/plans/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/plans/[uuid].vue")
  },
  {
    name: "reach-campaigns-scenarios-id",
    path: "/reach-campaigns/scenarios/:id()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/reach-campaigns/scenarios/[id].vue")
  },
  {
    name: "roundtables",
    path: "/roundtables",
    meta: roundtablesWyrWIY1RHrMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/roundtables.vue")
  },
  {
    name: "xtra",
    path: "/xtra",
    meta: index6JGKQYIt8SMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/7/nuxt/pages/xtra/index.vue")
  }
]