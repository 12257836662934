<template>
  <NuxtLayout :name="error.statusCode === 503 ? 'auth' : 'default'">
    <BaseCard>
      <div v-if="error.statusCode === 410 || error.statusCode === 404">
        <h1>{{ page?.title ?? '' }}</h1>
        <SofieText
          v-if="page?.template"
          :value="page.template.tekst"
          class="prose"
        />

        <NuxtLink
          :to="{name: 'index'}"
          class="btn btn-primary mt-8"
        >
          Terug naar dashboard
        </NuxtLink>
      </div>

      <div
        v-else-if="error.statusCode === 503"
        class="text-center"
      >
        <h1>Onderhoudsmodus</h1>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="error.message ? error.message.replace(/\n/g, '<br />') : 'Onderhoud'" />
      </div>

      <div
        v-else-if="error.statusCode === 403"
        class="text-center"
      >
        <h1>Geen toegang</h1>
        <nuxt-link
          :to="{name: 'index'}"
          class="btn btn-primary mt-8"
        >
          Terug naar dashboard
        </nuxt-link>
      </div>

      <div
        v-else
        class="text-center"
      >
        <h1>
          Er is een fout opgetreden
        </h1>
      </div>
    </BaseCard>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type {NuxtError} from '#app';

const props = defineProps<{
  error: NuxtError,
}>();

const {result} = usePageQuery({
  segments: '404',
}, () => ({
  enabled: props.error.statusCode === 404 || props.error.statusCode === 410,
}));
const page = computed(() => result.value?.page);

useHead(() => ({
  title: props.error.statusCode === 503
    ? 'Onderhoudsmodus'
    : (page.value?.seo?.meta_title ?? page.value?.title ?? 'Er is een fout opgetreden'),
}));
</script>
