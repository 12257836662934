<template>
  <div>
    <ul v-if="errors.length">
      <li
        v-for="(error, i) in errors"
        :key="i"
        class="mb-1 text-xs text-error"
      >
        {{ error }}
      </li>
    </ul>

    <div
      v-if="image"
      class="mb-2 max-h-fit max-w-fit rounded-md border p-1"
    >
      <NuxtImg
        :src="image.url"
        class="block max-h-52 max-w-96 rounded-md"
        alt="Preview"
      />
    </div>

    <FormKit
      type="file"
      class="mt-2"
      :loading="uploading"
      :ignore="ignore"
      @input="upload"
      @node="node = $event"
    />
  </div>
</template>

<script>
export default {
  props: {
    planUuid: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Object,
      default: () => null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    ignore: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:model-value'],
  setup() {
    return {
      apollo: useApolloClient().client,
    };
  },
  data: () => ({
    node: null,
    uploading: false,
    image: null,
  }),
  watch: {
    image() {
      if (JSON.stringify(this.image) === JSON.stringify(this.modelValue)) {
        return;
      }

      this.$emit('update:model-value', this.image);
    },
  },
  mounted() {
    if (this.modelValue) {
      this.image = this.modelValue;
    }
  },
  methods: {
    async upload(files) {
      if (!files[0]) {
        return;
      }

      try {
        this.uploading = true;

        const {data} = await this.apollo.mutate({
          mutation: UploadCampaignImageDocument,
          variables: {
            uuid: this.$route.params.uuid,
            plan: this.planUuid,
            image: files[0].file,
          },
        });

        this.image = data.uploadCampaignImage;
      } catch (e) {
        console.error(e);
      } finally {
        this.uploading = false;
        this.node?.input(null);
      }
    },
  },
};
</script>
