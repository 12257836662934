export default defineNuxtRouteMiddleware((route) => {
  const {isSupplier} = useUserRole();
  const router = useRouter();
  const user = useSofieUserWhenAuthenticated();

  if (!user.value) {
    return;
  }

  const path = route.path;
  // Get all available routes and map to their paths
  const allRoutes = router.getRoutes().map(a => a.path);

  if (isSupplier.value) {
    if (path.startsWith('/buying')) {
      return navigateTo(path.replace('buying', 'reach-campaigns'), {
        redirectCode: 301,
      });
    }

    return;
  }

  if (path.startsWith('/reach-campaigns') && !isExcluded(path)) {
    // Check if a version of the path with buying instead of reach-campaigns exists. If so, redirect to it
    if (allRoutes.includes(route.matched[0].path)) {
      return navigateTo(path.replace('reach-campaigns', 'buying'), {
        redirectCode: 301,
      });
    } else {
      return navigateTo('/', {
        redirectCode: 301,
      });
    }
  }
});

function isExcluded(path: string) {
  // List of pages which should not be redirected to '/buying'
  const exclusions = ['create', 'evaluation', 'planning', 'budget', 'cases', 'plans', 'merkzichtbaarheid'];
  let isExcluded = false;

  exclusions.forEach((exception) => {
    if (path.includes(exception)) {
      isExcluded = true;
    }
  });

  return isExcluded;
}
