<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const {locale} = useI18n();

useHead({
  htmlAttrs: {lang: locale},
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Bidfood Connect` : 'Bidfood Connect';
  },
});
</script>
