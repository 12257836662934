<template>
  <div ref="root">
    <FormKit
      v-bind="context"
      type="radio"
      fieldset-class="$reset"
      options-class="grid gap-5 sm:grid-cols-2 lg:grid-cols-3 gap-6"
      option-class="cursor-pointer min-w-60 rounded-lg bg-white p-6 shadow-sm ring-1 ring-gray has-[:checked]:ring-2 has-[:checked]:ring-green"
      label-class="$reset cursor-pointer"
      :model-value="context._value"
      :ignore="true"
      @update:model-value="context.node.input($event)"
    >
      <template #optionHelp="context">
        <div
          :class="context.classes.help"
          v-html="context.option.help"
        />
      </template>
    </FormKit>
  </div>
</template>

<script setup lang="ts">
import type {FormKitFrameworkContext} from '@formkit/core';

defineProps<{
  context: FormKitFrameworkContext
}>();

const root = ref<HTMLElement>();

onMounted(() => {
  root.value?.addEventListener('click', (event) => {
    (event.target as HTMLElement)
      .closest('li')
      ?.querySelector('input')?.click();
  });
});
</script>
