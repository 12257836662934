<template>
  <div ref="root">
    <FormKit
      v-bind="context"
      type="radio"
      fieldset-class="$reset"
      options-class="$reset"
      wrapper-class="!mb-0"
      decorator-class="translate-x-10 -ml-8"
      :model-value="context._value"
      :ignore="true"
      :label="undefined"
      @update:model-value="context.node.input($event)"
    >
      <template #label="context">
        <div
          :class="{'!bg-secondary/20' : context.value === context.option.value}"
          class="!ml-0 flex w-full cursor-pointer items-center justify-between gap-4 border-b border-gray-light py-3 pl-9 pr-2 transition hover:bg-secondary/10"
        >
          <div>
            <div>{{ context.option.label.split(' - ')[0] }}</div>
            <div class="text-sm italic text-gray-dark">
              {{ context.option.label.split(' - ')[1] }}
            </div>
          </div>

          <div class="flex items-center gap-2">
            <div v-if="context.option.price">
              {{ context.option.price }}
            </div>
          </div>
        </div>
      </template>

      <template #optionHelp="context">
        <div
          :class="context.classes.help"
          v-html="context.option.help"
        />
      </template>
    </FormKit>
  </div>
</template>

<script setup lang="ts">
import type {FormKitFrameworkContext} from '@formkit/core';

defineProps<{
  context: FormKitFrameworkContext
}>();

const root = ref<HTMLElement>();

onMounted(() => {
  root.value?.addEventListener('click', (event) => {
    (event.target as HTMLElement)
      .closest('li')
      ?.querySelector('input')?.click();
  });
});
</script>
