<template>
  <div
    class="overflow-hidden rounded-xl border border-gray-light bg-white"
    :class="{'space-y-4': !noSpace, 'p-4 md:p-8 ': hasPadding}"
  >
    <div
      v-if="title || $slots.controls"
      class="relative flex flex-wrap items-center justify-end"
    >
      <h3 class="flex-grow">
        {{ title }}
      </h3>
      <div class="flex w-full items-center sm:w-auto">
        <slot name="controls" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    noSpace: {
      type: Boolean,
      default: false,
    },
    hasPadding: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
